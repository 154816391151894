import Card from '../Card';

export default function CardGroup({ cards }) {
  return (
    <section className="card-group">
      {cards.map((card) => (
        <Card key={card.title} title={card.title} Icon={card.Icon} className="half">
          {card.children}
        </Card>
      ))}
    </section>
  );
}
