import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Image from '../components/Image';
import { getLayout } from '../components/Layouts/HomeLayout';
import RegistrationForm from '../components/RegistrationForm';
import useRedirectTo from '../lib/hooks/useRedirectTo';
import { useUser } from '../lib/user';
import authFetch from '../lib/utils/authFetch';
import About from './../components/About';

const EntityCard = dynamic(() => import('./../components/Entities/Entity/EntityCard'));

function Home({ entities }) {
  const redirectTo = useRedirectTo('/discover');
  const user = useUser().state.user;
  const title = `The Best Film and TV Buffs Discussion For London UK Audience - ${process.env.NEXT_PUBLIC_APP_NAME}`;

  return (
    <>
      <NextSeo
        canonical={process.env.NEXT_PUBLIC_DOMAIN}
        title={title}
        description="The Best Film & TV Buffs Discussion Community For London, UK Audience. FTVBs is a discussion community where you can discuss everything about anything to do with Film & TV with the London UK Audience and millions of people around the world on FTVBs."
      />
      <section className="home-item image-wrapper">
        <section className="logo">
          <Image
            alt=""
            width="208"
            height="208"
            layout="intrinsic"
            src="/assets/png/The_Best_Film_&_TV_Buffs_Discussion_ForLondon_UK_Audience_FTVBs.png"
          />
        </section>
        <section className="hero-image">
          <section className="overlay" />
          <section className="trending-grid">
            {entities.map((entity, i) => (
              <EntityCard entity={entity} key={entity.id} alt priority={i < 10} />
            ))}
          </section>
        </section>
      </section>
      <section className="home-item text-wrapper">
        <section className={!user.id ? 'item-hero' : 'p-3 lg:p-14'}>
          <section className="content">
            <section className="logo">
              <Image
                alt="The Best Film & TV Buffs Discussion For London, UK Audience FTVBs"
                width="64"
                height="64"
                layout="intrinsic"
                src="/assets/png/The_Best_Film_&_TV_Buffs_Discussion_For_London_UK_Audience_FTVBs.png"
              />
            </section>
            <section className="headings">
              <h1>
                The Best Film and TV Buffs Discussion For London UK Audience -{' '}
                {process.env.NEXT_PUBLIC_APP_NAME}
              </h1>
              {!user.id && (
                <h2>
                  Join The Best Film and TV Buffs Discussion Commiunity For London UK Audience{' '}
                  {process.env.NEXT_PUBLIC_APP_NAME} Today
                </h2>
              )}
            </section>
            {!user.id && <RegistrationForm hideTitle redirect={redirectTo} />}
          </section>
        </section>
        <About hideLogo />
      </section>
    </>
  );
}

Home.getLayout = getLayout;

export const getStaticProps = async () => {
  const entities = await authFetch(`${process.env.NEXT_PUBLIC_API}/trending`);
  return {
    props: {
      entities,
      analytics: {
        category: 'Pages',
        name: 'Home',
      },
      revalidate: 60 * 60 * 24 * 7,
    },
  };
};

export default Home;
