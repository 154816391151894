import Footer from '../../Footer';

const HomeLayout = ({ children }) => {
  return (
    <section className="app-home">
      <section className="wrapper">
        <main className="main">{children}</main>
        <Footer />
      </section>
    </section>
  );
};

export const getLayout = (page) => <HomeLayout>{page}</HomeLayout>;

export default HomeLayout;
