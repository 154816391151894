import { useRouter } from 'next/dist/client/router';
import { BiFilm, BiGroup, BiMessageRoundedDots, BiTv } from 'react-icons/bi';
import siteSocials from '../../lib/consts/siteSocials';
import Button from '../Button';
import CardGroup from '../Cards/CardGroup/CardGroup';
import { Logo } from '../Icons';

const cards = [
  {
    title: 'Films',
    children:
      "Everything from 1980's cult horror to the newest blockbusters - we've got it all. View the latest blockbusters and trending movies.",
    Icon: BiFilm,
  },
  {
    title: 'TV Shows',
    children: (
      <>
        Whether you're a{' '}
        <Button as="a" href="/e/title/1520211" variant="clear" className="a">
          Walking Dead
        </Button>{' '}
        fanatic or a diehard{' '}
        <Button as="a" href="/e/title/0944947" variant="clear" className="a">
          Game of Thrones
        </Button>{' '}
        fan, we've got you covered.
      </>
    ),
    Icon: BiTv,
  },
  {
    title: 'Actors & Casts',
    children:
      'Talk about anyone from your favourite director, cinematographer writer to that B-list actress you just discovered on Netflix.',
    Icon: BiGroup,
  },
  {
    title: 'Community Discussions',
    children:
      'A community of both average moviegoers and passionate critics, FTVBs has the largest collection of Film & TV Show discussions on the web.',
    Icon: BiMessageRoundedDots,
  },
];

export default function About({ hideLogo }) {
  const router = useRouter();
  const Title = router.route === '/' ? 'h2' : 'h1';
  return (
    <section className="about">
      <section className="content">
        {!hideLogo && <Logo alt />}
        <Title>FTVBs - The Best Film and TV Buffs Discussion For London UK Audience</Title>
        <p>
          This is{' '}
          <Button variant="clear" as="a" href="/">
            the best Film & TV Buffs discussion community for London, UK audience
          </Button>
          . FTVBs is a discussion community where you can discuss everything about anything to do
          with Film & TV with the London UK Audience and millions of people around the world on
          FTVBs. An online community for discussing Films, TV Shows, Actors or anything to do with
          everything about Film & TV.
        </p>
        <p>
          If you have a question about a film you recently saw, or just want to discuss and see what
          other people have to say?
        </p>
        <p>
          Find it all and more on FTVBs,{' '}
          <Button variant="clear" as="a" href="/">
            the best Film and TV Buffs discussion community for discussing Films, TV Shows and
            Actors.
          </Button>{' '}
          You can discuss here with people all over the world. Especially for the London, UK based
          Film & TV Buffs audience.
          {router.route !== '/about' && (
            <>
              If you wanna learn more about our discussion community, You can go to our{' '}
              <Button variant="clear" as="a" href="/about">
                About
              </Button>{' '}
              page.
            </>
          )}
        </p>
        <h3>What is the best Film &amp; TV buffs discussion community for London, UK audience?</h3>
        <p>
          FTVBs is{' '}
          <Button variant="clear" as="a" href="/">
            the best Film &amp; Tv buffs discussion online community for London, UK audience for
            discussing Films, TV Shows, Actors etc
          </Button>{' '}
          or anything to do with everything about Film &amp; TV.
        </p>
        <h3>
          How can I find the best Film &amp; TV buffs discussion community for London, UK audience?
        </h3>
        <p>
          You can search FTVBs and go to our{' '}
          <Button
            as="a"
            href="/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021"
            variant="clear"
            className="a"
          >
            Discover
          </Button>{' '}
          page and start your discussion about the Films & TV Shows.
        </p>
        <h3>What is FTVBs?</h3>
        <p>
          FTVBs is the best Film &amp; Tv buffs discussion community for London, UK audience. This
          is your online community for discussing Films, TV Shows, Actors or anything to do with
          everything about Film &amp; TV.
        </p>
        <h3>
          How can I learn more{' '}
          <Button as="a" href="/about" variant="clear" className="a">
            about FTVBs
          </Button>
          ?
        </h3>
        <p>
          You can be connected with us on our social media's. FTVBs is a discussion community where
          you can discuss everything about anything to do with Film &amp; TV with the London UK
          Audience and the world on FTVBs.
        </p>
        <h3>Find us on</h3>
        <section className="flex relative space-x-4">
          {siteSocials.map((social) => (
            <Button
              key={social.label}
              href={social.link}
              as="a"
              className="socials"
              variant="clear"
              target="_blank"
              rel="noreferrer noopenner"
            >
              {social.icon}
              <span className="sr-only">{social.label}</span>
            </Button>
          ))}
        </section>
        <h2>
          How can we use FTVBs the best Film &amp; TV buffs discussion community for London, UK
          audience?
        </h2>
        <h3>
          Tip: 3 Ways to Use{' '}
          <Button variant="clear" as="a" href="/">
            FTVBs
          </Button>
        </h3>
        <ol>
          <li>
            <strong>Have something particular in mind?</strong> Use the search box to find
            discussions about specific Films, TV Shows, Actors or Casts
          </li>
          <li>
            <strong>Want to see what's popular?</strong>{' '}
            <Button
              as="a"
              href="/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021"
              variant="clear"
              className="a"
            >
              Discover
            </Button>{' '}
            what's trending
          </li>
          <li>
            <strong>Feeling adventurous?</strong> Create a Room or jump into Live discussion
          </li>
        </ol>
      </section>
      <CardGroup cards={cards} />
      <section className="content mt-8">
        <b>
          You must have visited{' '}
          <Button
            as="a"
            variant="clear"
            href="https://www.imdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            IMDb
          </Button>{' '}
          to discuss the Movies & TV Shows and maybe you are{' '}
          <Button
            as="a"
            variant="clear"
            href="https://moviechat.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            moviechat
          </Button>{' '}
          visitor...we are nothing different from them...We will be best choice for you if you are
          looking for The Best Film and TV Buffs Discussion Community For London UK Audience.
        </b>
      </section>
    </section>
  );
}
